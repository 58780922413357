import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import '../src/assets/style.scss';
import * as LottiePlayer from "@lottiefiles/lottie-player";
import VueSimpleAlert from "vue-simple-alert";
import router from './router'
import VueFormulate from '@braid/vue-formulate';
import CKEditor from 'ckeditor4-vue';
import store from './store'

//components / allowing items to use globally 
Vue.component('v-select', vSelect);
Vue.use(VueTelInput);
Vue.use(BootstrapVue);
Vue.use(VueSimpleAlert);
Vue.use(LottiePlayer);
Vue.use(VueFormulate);
Vue.use(IconsPlugin);
Vue.use( CKEditor );

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  computed: {
    testenv() {
      return this.$route?.query?.test?.toLowerCase() === 'true' || false
    }
  }
}).$mount('#app')
