<template>
  <div class="m-3">
    <div v-if="fetching">Fetching everyone...</div>
    <div v-else>
      <div class="d-flex justify-content-end">
        <b-row class="my-1 align-items-center">
          <b-col sm="3">
            <label>Search: </label>
          </b-col>
          <b-col sm="9">
            <b-form-input v-model="search" type="text"></b-form-input>
          </b-col>
        </b-row>
        </div>
        <div class="m-1">
        <b-row>
            Results: {{search ? filteredItems.length : emailDetails.length}}
        </b-row>
        </div>
      <b-table
        sort-desc
        :sort-by.sync="sortBy"
        :fields="tableFields"
        :items="emailDetails"
        :filter="search"
        @filtered="onFilter"
        :tbody-tr-class="inGroupCheck"
      >
        <template #cell(#)="row">
            {{row.index+1}}
        </template>
        <template #cell(group_id)="row">
            {{row.item.group_id !== 'null' && row.item.group_id ? row.item.group_id : 'Not in a group registration (null)'}}
        </template>

        <template #cell(registered_date)="row">
            {{dayjs(row.item.registered_date).format('DD/MM/YYYY - h:mm A')}}
        </template>

        <template #cell(action)="data">
          <div class="d-flex">
            <b-button
              class="mx-1"
              variant="primary"
              @click="viewEmail(data.item.cr098_registrationrecordid)"
            >
              View Email
            </b-button>
            <b-button
              class="mx-1"
              v-if="data.item.teng_receipt_id"
              variant="info"
              @click="viewReceipt(data.item.cr098_registrationrecordid)"
            >
              View Receipt
            </b-button>
          </div>
        </template>

        <template #cell(payment_successful)="data">
          <span v-if="data.item.teng_receipt_id" class="yes">Yes (true)</span>
          <span v-else class="no">No (false)</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { sha256 } from "crypto-hash";
import axios from "axios";
import dayjs from "dayjs";

const receiptUrlBase = "https://registration.grading.thetengcompany.com/receiptView/";

export default {
  data() {
    return {
      pw: "",
      fetching: true,
      emailDetailsRaw: {},
      sha256,
      sortBy: "payment_successful",
      search: '',
      filteredItems: [],
      dayjs
    };
  },
  async beforeRouteEnter(to, from, next) {
    const localPw = localStorage.getItem("pw");
    const hash =
      "65e5605541bd5803c10888aa6e7a321f175e28941656b3e16f2e4beb5ff1285d"; //j74j7gjs6y27JF&H73ghdj98w&hghE&()097$2

    if (localPw && (await sha256(localPw)) === hash) {
      next((vm) => {
        vm.pw = hash;
        vm.$store.commit("setPw", hash);
      });
      return;
    }

    let promptPw = async () => {
      let result = prompt("Enter the password");
      if (result && (await sha256(result)) === hash) {
        localStorage.setItem("pw", result);
        next((vm) => {
          vm.pw = hash;
          this.$store.commit("setPw", hash);
        });
        return true;
      }
      alert("Wrong password");
      window.location.reload()
      return false;
    };
    let result = false;
    while (!result) {
      result = await promptPw();
    }
  },
  async mounted() {
    await this.fetchTable();
    this.fetching = false;
  },
  methods: {
    onFilter(filteredItems) {
        this.filteredItems = filteredItems;
    },
    viewReceipt(id) {
      window.open(`${receiptUrlBase}${id}${this.$root.testenv ? "?test=true" : ''}`, "_blank");
    },
    viewEmail(id) {
      this.$router.push("/manual-notifications/email/" + id + (this.$root.testenv ? "?test=true" : ''));
    },
    async fetchTable() {
      this.emailDetailsRaw = await axios.post("/api/email_details", {
        pw: this.pw,
        test: this.$root.testenv
      });
    },
  },
  computed: {
    emailDetails() {
      return (
        this.emailDetailsRaw?.data?.data?.value?.map((e) => {
          return {
            cr098_registrationrecordid: e.cr098_registrationrecordid,
            full_name: e.cr098_fullname,
            email: e.cr098_email,
            registered_date: e.createdon,
            group_id: e.cr098_groupid,
            teng_receipt_id: e.teng_receipt_id,
            recepient_email: e.teng_familygroupreceiptreceiveremail,
            payment_successful: !!e.teng_receipt_id,
            total_paid: e.cr098_paymentamount ? e.cr098_paymentamount : 0,
            postage_fee: e.teng_postagefee,
            total_without_postage_fee: e.teng_amountwithoutpostagefee
              ? e.teng_amountwithoutpostagefee
              : 0,
            "transaction_id_(Stripe)": e.teng_transactionid,
          };
        }) || []
      );
    },
    tableFields() {
      const arr = [];
      const exceptions = ["cr098_registrationrecordid"];
      arr.push({
        key: '#',
      })
      for (let key in this.emailDetails[0]) {
        if (!exceptions.includes(key)) {
          arr.push({
            key,
            sortable: true,
          });
        }
      }

      arr.push({
        key: "action",
      });

      return arr;
    },
  },
};
</script>

<style></style>
