export default {
    english: {
        title:"Chinese Instrumental Music Grading Examinations Online Registration Form",
        personal:"Personal",
        academic:"Academic",
        examBook:"Exam Book",
        examination:"Examination",
        summary:"Summary",
        personalDetails:"Personal Details",
        nric:"BC/NRIC/Passport No.",
        lastFour:"Last four characters, e.g. 123A",
        partialIdRequired:"Partial ID is required.",
        fullName:"Full Name",
        fullNameMustBe3:"Full Name must be at least 3 characters long. ",
        asInNric:"As in BC/NRIC/Passport ",
        chineseName:"Chinese Name (Optional)",
        gender:"Gender",
        chooseAnOption:"Choose an Option",
        male:"Male",
        female:"Female",
        fender:"Gender is required. ",
        yearOfBirth:"Year of Birth",
        yearOfBirthRequired:"Year of Birth is required. ",
        nationality:"Nationality",
        singapore:"Singaporean",
        malaysia:"Malaysian",
        indonesia:"Indonesian",
        others:"Others",
        nationalityOthers:"Nationality (Others)",
        nationalityIsRequired:"Nationality is required. ",
        race:"Race",
        chinese:"Chinese",
        malay:"Malay",
        indian:"Indian",
        raceIsRequired:"Race is required. ",
        email:"Email",
        emailIsRequired:"Email is required. ",
        isNotAValidEmail:"Not a valid email address. ",
        phoneNumber:"Phone Number",
        phoneNumberRequired:"Phone Number is required. ",
        phoneNumberMustBe7:"Phone Number must be at least 7 characters long. ",
        phoneNumberMustBeANumber:"Phone Number must be a number. ",
        address:"Address",
        addressIsRequired:"Address is required. ",
        addressMustBe10:"Address must be at least 10 characters long. ",
        postalCode:"Postal Code",
        postalCodeMustBe5:"Postal Code must be at least 5 characters long. ",
        next:"Next",
        previous:"Previous",
        cancel: "Cancel",
        pleaseEnterAllNecessary:"Please enter all necessary fields/correct the fields.",
        thisIcIsNotValid:"This IC is not valid. ",
        academicDetails:"Academic Details",
        school:"School/Music School (Optional)",
        schoolNameMustBe3:"School name must be at least 3 characters long. ",
        instructor:"Instructor (Optional)",
        instructorEmail:"Instructor's Email (Optional)",
        instructorsNameWill:"Instructor's name will be printed on the certificate.",
        instructorsEmailInst:"A copy of your registration confirmation will be sent to your instructor via email.",
        instructorsNameMustBe3:"Instructor’s name must be at least 3 characters long.",
        examBookDetails:"Exam Book Details",
        doYouHaveAnExamBook:"Do you have an Exam Book?",
        yes:"Yes",
        no:"No",
        examBookSerialNumber:"Exam Book Serial Number",
        egSerial:"e.g. GZ12312312",
        bookSerialNumberIsRequired:"Book serial number is required. ",
        bookSerialNumberMustBe10:"Book serial number must be at least 10 characters long. ",
        youCanVisit:"You can visit this link for more information or to purchase one for your exam.",
        youCanVisit2:"You can visit this link for more information on the exam fee structure.",
        examDetails:"Exam Details",
        instrument:"Instrument",
        instrumentIsRequired:"Instrument is required. ",
        grade:"Grade",
        gradeIsRequired:"Grade is required. ",
        guzheng:"Guzheng",
        erhu:"Erhu",
        dizi:"Dizi",
        pipa:"Pipa",
        yangqin:"Yangqin",
        ruan:"Ruan",
        liuqin:"Liuqin",
        gaoyinSheng:"Gaoyin Sheng",
        zhongyinShengGrade:"Zhongyin Sheng (Grade 1 to 8 only)",
        zhongyinSheng:"Zhongyin Sheng",
        gaoyin:"Gaoyin Suona",
        zhongyinSuona:"Zhongyin Suona",
        zhongyinSuonaGrade:"Zhongyin Suona (Grade 1 to 8 only)",
        cello:"Cello",
        doubleBass:"Double Bass",
        hulusi:"Hulusi (Grade 1 to 8 only)",
        guanzi:"Guanzi",
        percussion:"Percussion",
        guqin:"Guqin",
        grade1:"Grade 1",
        grade2:"Grade 2",
        grade3:"Grade 3",
        grade4:"Grade 4",
        grade5:"Grade 5",
        grade6:"Grade 6",
        grade7:"Grade 7",
        grade8:"Grade 8",
        certificateInProfessional:"Certificate In Professional Performance",
        grade8CertOr:"Grade 8 Certificate or equivalent qualifications",
        noFileChosen:"No file chosen",
        browse:"Browse",
        pleaseUploadYourCertificate:"Please upload your certificate. Max 10MB file size.",
        moreFiles:"More files (Optional)",
        addRegistration:"Add Registration",
        areYouSureYouWant:"Are you sure you want to add this registration?",
        youCanSubmit:"You can submit an individual registration or a group registration.",
        yourRegistrationDetails:"Your registration details have been automatically saved to this computer and will be automatically loaded if you navigate back to this page.",
        actions:"Actions",
        modify:"Modify",
        remove:"Remove",
        iWeAgree:"I/We agree that all information furnished are true and correct, and agree to all terms and conditions.",
        pleaseAcceptTheAboveTerm:"Please accept the above term.",
        iWeWouldLike:"I/We would like to sign up for mailing list updates.",
        addAnotherEntry:"Add Another Entry?",
        checkOut:"Check out",
        areYouSureYouWantToCheckOut:"Are you sure you want to check out?",
        submittingRegistrationForm:"Submitting registration form…",
        pleaseWaitForAMinute:"Please wait for a minute.",
        doNotCloseYourBrowser:"Do not close your browser window or navigate away. ",
        payment:"Payment",
        dueNow:"Due now:",
        cardNumber:"Card number",
        expiration:"Expiration",
        cvc:"CVC",
        country:"Country",
        googlePay:"Google Pay selected for check out. ",
        anotherStep:"Another step will appear after submitting your order to complete your purchase details.",
        payNow:"PayNow selected for check out. ",
        afterSubmitting:"After submitting your order, scan the QR code using your preferred banking or payment app.",
        grabPay:"GrabPay selected for check out.",
        afterSubmittingYourOrder:"After submitting your order, you will be redirected to securely complete your purchase.",
        pay:"Pay",
        yourRegistrationHasBeenSubmitted:"Your registration has been submitted.",
        ifYouHaveCompleted:"If you have completed the payment, the email address(es) of the registered user(s) should receive the link to the receipt within 5 minutes. Please check your spam/junk folder as well. You can return to the <a href='/'>main page here.</a>",
        postageOption: "Postage Option",
        localRegisteredMail: "Local Registered Mail $0.00 (per candidate)",
        overseasRegisteredMail: "Overseas Registered Mail $15.00 (per candidate)",
        bulkPosting: "Bulk Posting to Schools or Instructors $0.00 (Please check with your school or instructor on this arrangement.)",
        pleaseFillIn: "Please fill in ",
        unableToSubmit: "Unable to submit. Please retry.",
        thisBookSerialNumber: "This book serial number has been registered.",
        thisSerialNumber: "This serial number is invalid.",
        youMustAgree: "You must agree before submitting.",
        bookSerialNumber: "Book serial number must be unique.",
        loadPreviously: "Load previously saved registration information?",
        family: "Family",
        group: "Group",
        youCanSummary: "You can click the red circle on top of the \"Summary\" to move to the Summary Page.",
        familyOrGroupRegistration: "Family or Group registration",
        familyOrGroupReceiptReceiverName: 'Family/Group Receipt Receiver Name',
        familyOrGroupReceiptReceiverEmail: 'Family/Group Receipt Receiver Email',
        sureCancel: "Are you sure you want to cancel your changes?",
        sureRemove: "Are you sure you want to remove this entry?",
        price: 'Price (S$)',
        postageFee: 'Postage Fee (S$)',
        total: "Total (S$)",
        typeExam:"Mode of Examination",
        digitalexam:"Digital Exam",
        facetoface:"Face-to-face Exam"
    },
    chinese: {
        title:"华乐器乐考级线上报名表格",
        personal:"个人资料",
        academic:"学术资料",
        examBook:"考级乐谱",
        examination:"考级资料",
        summary:"总结",
        personalDetails:"个人资料",
        nric:"出生证/身份证/护照号码",
        lastFour:"最后4位号码、字母，如：123A",
        partialIdRequired:"请填上出生证/身份证/护照号码最后4位号码、字母",
        fullName:"姓名",
        fullNameMustBe3:"姓名必须有至少3个字母。",
        asInNric:"如同出生证/身份证/护照号码所显示",
        chineseName:"中文姓名（可省略）",
        gender:"性别",
        chooseAnOption:"请选其中一项",
        male:"男",
        female:"女",
        fender:"请填上性别。",
        yearOfBirth:"出生年份",
        yearOfBirthRequired:"请填上出生年份。",
        nationality:"国籍",
        singapore:"新加坡",
        malaysia:"马来西亚",
        indonesia:"印尼",
        others:"其他",
        nationalityOthers:"国籍（其他）",
        nationalityIsRequired:"请填上国籍。",
        race:"种族",
        chinese:"华族",
        malay:"马来族",
        indian:"印度族",
        raceIsRequired:"请填上种族。",
        email:"电邮地址",
        emailIsRequired:"请填上电邮地址。",
        isNotAValidEmail:"不是有效的电邮地址。",
        phoneNumber:"联络号码",
        phoneNumberRequired:"请填上联络号码。",
        phoneNumberMustBe7:"联络号码必须有至少7个号码。",
        phoneNumberMustBeANumber:"联络号码必须填上号码。",
        address:"住址",
        addressIsRequired:"请填上住址。",
        addressMustBe10:"住址必须有至少10个字母。",
        postalCode:"邮编",
        postalCodeMustBe5:"邮编必须有至少5个号码。",
        next:"下一页",
        previous:"前一页",
        cancel: "取消",
        pleaseEnterAllNecessary:"请填上所需或正确的资料。",
        thisIcIsNotValid:"出生证/身份证/护照号码不是正确的。",
        academicDetails:"学术资料",
        school:"学校/音乐学校（可省略）",
        schoolNameMustBe3:"校名必须有至少3个字母。",
        instructor:"指导老师（可省略）",
        instructorEmail:"指导老师电邮地址 (可省略)",
        instructorsNameWill:"证书上将会印有指导老师的名字。",
        instructorsEmailInst:"您的报名确认邮件将通过电子邮件发送至您的指导老师。",
        instructorsNameMustBe3:"指导老师姓名必须有至少3个字母。",
        examBookDetails:"考级乐谱资料",
        doYouHaveAnExamBook:"您拥有考级乐谱吗？",
        yes:"有",
        no:"没有",
        examBookSerialNumber:"考级乐谱编号",
        egSerial:"例如：GZ12312312",
        bookSerialNumberIsRequired:"请填上乐谱编号。",
        bookSerialNumberMustBe10:"乐谱编号必须有至少10个字母。",
        youCanVisit:"您可点击这里查询更多详情或购买考级乐谱。",
        youCanVisit2:"您可点击这里查询有关考级报名费之详情。",
        examDetails:"考级资料",
        instrument:"乐器",
        instrumentIsRequired:"请填上乐器。",
        grade:"级别",
        gradeIsRequired:"请填上级别。",
        guzheng:"古筝",
        erhu:"二胡",
        dizi:"笛子",
        pipa:"琵琶",
        yangqin:"扬琴",
        ruan:"阮",
        liuqin:"柳琴",
        gaoyinSheng:"高音笙",
        zhongyinShengGrade:"中音笙（只限第一至八级）",
        zhongyinSheng:"中音笙",
        gaoyin:"高音唢呐",
        zhongyinSuonaGrade:"中音唢呐（只限第一至八级）",
        zhongyinSuona:"中音唢呐",
        cello:"大提琴",
        doubleBass:"倍大提琴",
        hulusi:"葫芦丝（只限第一至八级）",
        guanzi:"管子",
        percussion:"打击乐",
        guqin:"古琴",
        grade1:"一级",
        grade2:"二级",
        grade3:"三级",
        grade4:"四级",
        grade5:"五级",
        grade6:"六级",
        grade7:"七级",
        grade8:"八级",
        certificateInProfessional:"演奏文凭",
        grade8CertOr:"第八级证书或同等资格",
        noFileChosen:"没有选中任何文件",
        browse:"浏览",
        pleaseUploadYourCertificate:"请上载您的证书。文件不能超过10MB。",
        moreFiles:"上载更多文件（可省略）",
        addRegistration:"提交报名",
        areYouSureYouWant:"您是否确定要提交这份报名？",
        youCanSubmit:"您可以提交个人报名或群组报名。",
        yourRegistrationDetails:"您的报名资料已自动保存到此器件，如果您返回此页面，资料将自动加载。",
        actions:"行动",
        modify:"更正",
        remove:"删除",
        iWeAgree:"我/我们确定以上所提供的资料属实，并同意所有考级章程与条款。",
        pleaseAcceptTheAboveTerm:"请接受以上条款。",
        iWeWouldLike:"我/我们想订阅有关鼟的资讯。",
        addAnotherEntry:"添加报名？",
        checkOut:"付款",
        areYouSureYouWantToCheckOut:"您是否确定要付款？",
        submittingRegistrationForm:"提交报名中。。。",
        pleaseWaitForAMinute:"请稍等。",
        doNotCloseYourBrowser:"请不要关闭或离开此浏览页。",
        payment:"付款",
        dueNow:"需付的款项：",
        cardNumber:"信用卡号码",
        expiration:"有效期",
        cvc:"安全码",
        country:"国家",
        googlePay:"已选择 Google Pay 进行付款。",
        anotherStep:"提交报名后将出现另一个步骤以完成付款。",
        payNow:"已选择 PayNow进行付款。",
        afterSubmitting:"提交报名后，使用您首选的银行或支付应用程序扫描二维码。",
        grabPay:"已选择 GrabPay进行付款。",
        afterSubmittingYourOrder:"提交报名后，您将被重定向以安全地完成付款。",
        pay:"付款",
        yourRegistrationHasBeenSubmitted:"您的报名已提交。",
        ifYouHaveCompleted:"如果您已完成付款，注册用户的电邮地址应在 5 分钟内收到收据链接。 请同时检查您的垃圾邮件或垃圾文件夹。 您可以在此处<a href='/'>返回主页。</a>",
        postageOption: "成绩邮寄方式",
        localRegisteredMail: "本地挂号邮件 $0.00 (每人）",
        overseasRegisteredMail: "海外挂号邮件 $15.00 (每人）",
        bulkPosting: "集体邮递至学校或指导老师 $0.00 (请与学校或指导老师确认有关这项安排。)",
        pleaseFillIn: "请填上 ",
        unableToSubmit: "无法提交。请重试。",
        thisBookSerialNumber: "本书序号已注册。",
        thisSerialNumber: "此乐谱编号无效。",
        youMustAgree: "您必须在提交前同意。",
        bookSerialNumber: "图书序列号必须是唯一的。",
        loadPreviously: "加载以前保存的注册信息？",
        family: "家庭",
        group: "团体",
        youCanSummary: "您可以单击“摘要”顶部的红色圆圈移动到摘要页面。",
        familyOrGroupRegistration: "家庭或团体报",
        familyOrGroupReceiptReceiverName: '家庭或团体报名收据收件人姓名',
        familyOrGroupReceiptReceiverEmail: '家庭或团体报名收据收件人电邮地址',
        sureCancel: "确定要取消更改吗？",
        sureRemove: "您确定要删除此条目吗？",
        price: "价格 (S$)",
        postageFee: '邮费 (S$)',
        total: '总数 (S$)',
        typeExam:"考级形式",
        digitalexam:"线上视频考级",
        facetoface:"实体考级"

    }
}