<template lang="pug">
div.center
  div(v-if="fetching")
    h4#title Fetching your receipt...
    .loader#loadingIcon
  div(v-else)
    div(v-if="(!validId || !receipt.length)")#errorPage
      h4 An Error Occurred.
      div 
        | <br/>Invalid id detected. <br/> <br/>
        | Are you sure you had registered before? Do contact us if you had registered before. <br/> <br/>
        | Otherwise, do <a href="/">register here</a>
    div(v-else)
      <br/><br/><br/>
      .receipt-container
        h4 Receipt
        <br/>
        table(cellpadding="0", cellspacing="0")
          tr.top
            td(colspan="4")
              table
                tr
                  td.title
                    | {{ receipt[0].name }}
                    br
                    | {{ receipt[0].uen }}
                    br
                    br
                    span(v-if="!receipt[0].groupId")
                      br
                      | {{ receipt[0].fullName }}
                      br
                      | {{ receipt[0].email }}
                      br
                      | {{ receipt[0].address }}
                      br
                      | {{ receipt[0].postalCode }}
                    span(v-else)
                      br
                      | {{ receipt[0].receiptReceiverName }}
                      br
                      | {{ receipt[0].receiptReceiverEmail }}
                  td
                    | Invoice: {{ receipt[0].receiptId }}
                    br
                    |
                    | Created On: {{ receipt[0].date }}
          tr.information
            td(colspan="4")
              table
                tr
                  td
          tr.heading
            td(colspan="3") Fees Paid
            td Amount (SGD)
          tr.details(v-for="(r,i) in receipt")
            td(colspan="3" :class="'pb-0'") 
              span(v-if="i === 0") <strong>Registration Fees</strong><br/>
              div(  :key='`receipt_${i}`') {{r.fullName}} (Grade: {{gradeOptions[r.grade]}}, Instrument: {{instrumentTypes[r.instrument]}}) , Type of Exam : {{r.digital ? 'Digital': 'Face-to-face' }}) 
            td(:class="'pb-0'") <br v-if="i === 0" /> {{ getRowAmount(r.grade, r.digital)}}
          
          tr.details(v-for="(r,i) in receipt")
            td(colspan="3" :class="'pb-0'") 
              span(v-if="i === 0") <strong>Postage Fees</strong><br/>
              div(  :key='`receipt_${i}`') {{r.fullName}} ({{postageOption}}) 
            td(:class="'pb-0'") <br v-if="i === 0" /> {{ postageOptionAmount.toFixed(2)}}
          
          
          <br/><br/>
          tr.total
            td(colspan="3" )
            td Total: S${{receiptTotal}}
        
        <br/>
        
        <br/>
        <br/>
        div(style="text-align: left;") Payment Method: {{receipt[0].paymentMode}}
        div(style="text-align: left;") Transaction ID: {{receipt[0].transactionId}}
        <br/>
        .center
          <br/>
          | This is an auto generated receipt by the system. No signature is required for this document.
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import { getprice} from "../../getprice.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault('Asia/Singapore')

export default {
  name: "receiptView",
  data() {
    return {
      fetching: true,
      validId: true,
      rawReceipt: null,
      total: 0,
      groupid: [],
      instrumentTypes: {
          895510000: "Guzheng",
          895510001: "Erhu",
          895510002: "Dizi",
          895510003: "Pipa",
          895510004: "Yangqin",
          895510005: "Ruan",
          895510006: "Liuqin",
          895510007: "Gaoyin Sheng",
          895510008: "Zhongyin Sheng",
          895510009: "Gaoyin Suona",
          895510010: "Zhongyin Suona",
          895510011: "Cello",
          895510012: "Double Bass",
          895510013: "Hulusi",
          895510014: "Guanzi",
          895510015: "Percussion",
          895510016: "Guqin"
        },
        gradeOptions: {
          895510000: 'Grade 1',
          895510001: 'Grade 2',
          895510002: 'Grade 3',
          895510003: 'Grade 4',
          895510004: 'Grade 5',
          895510005: 'Grade 6',
          895510006: 'Grade 7',
          895510007: 'Grade 8',
          895510008: 'Certificate In Professional Performance'
        }
    };
  },
  methods: {
    getRowAmount(grade, digital=false) {
      grade = grade+ '';
      let amount = 0;
          amount += getprice(grade, digital ? 2 : 1);
      return amount.toFixed(2);
    }
  },
  async mounted() {
    try {
      document.querySelector('meta[name=viewport]').setAttribute('content', 'width=device-width, initial-scale=0.1');
      const id = this.$route.params?.id;
      console.log(this.$route.params.id)
      let result = {};
      if (!id) {
        this.validId = false;
        this.fetching = false;
        return;
      } 

      const { data } = await axios.get(`/api/receipt?group_id=${id}${this.$root.testenv ? "&test=true" : ''}`);
      this.rawReceipt = data.value;

      if (!data.value.length) {
        result = await axios.get(`/api/receipt?registration_id=${id}${this.$root.testenv ? "&test=true" : ''}`);
        this.rawReceipt = result.data.value;
        this.validId = true;
        this.fetching = false;
        await this.$nextTick();
        window.print();
        return; 
      }
      
      this.fetching = false;
      this.validId = true;

      await this.$nextTick();
      window.print();
      
    } catch(e) {
      this.fetching = false;
    }
  },
  computed: {
    checkPrice(){
      return this.rawReceipt.map(s=>s.cr098_grade);
    },
    postageOptionAmount() {
      if (this.postageOption == this.bulkPostingString) {
        return 0.00;
      }

      if (this.postageOption == this.localPostingString) {
        return 0.00;
      }

      if (this.postageOption == this.overseasPostingString) {
        return 15.00;
      }

      return 0;
    },
    instrument() {
      if (this.fetching) {
        return ''
      }

      return this.instrumentTypes[this.receipt[0].instrument]
    },
    grade() {
      if (this.fetching) {
        return ''
      }

      return this.gradeOptions[this.receipt[0].grade]
    },
    bulkPostingString(){
      return 'Bulk Posting to Schools or Instructors: $0.00'
    },
    localPostingString(){
      return 'Local Registered Mail: $0.00';
    },
    overseasPostingString(){
      return 'Overseas Registered Mail: $15.00';
    },
    postageOption(){
      if (this.fetching) {
        return ''
      }

      const postageOption = [];
      if (this.rawReceipt[0].teng_bulkposting) {
        postageOption.push(this.bulkPostingString)
      }

      if (this.rawReceipt[0].teng_localregisteredmail) {
        postageOption.push(this.localPostingString);
      }

      if (this.rawReceipt[0].teng_overseasregisteredmail) {
        postageOption.push(this.overseasPostingString);
      }
      return postageOption.join(', ');
    },
    receiptTotal(){
      if (!this.rawReceipt) {
        return 0;
      }
      return this.receipt.reduce((p, c) => {
        return p + +this.getRowAmount(c.grade, c.digital) + +this.postageOptionAmount;
      }, 0).toFixed(2);
      //return this.receipt.amount;
    },
    receipt() {
      if (!this.rawReceipt) {
        return [];
      } 
      
      return this.rawReceipt.map((r) => {
        return {
          uen: r.cr098_companyuen,
          name: r.cr098_paymentcompanyname,
          date: dayjs(r.cr098_receiptdate).format("DD/MM/YYYY HH:mm"),
          rawDate: r.cr098_receiptdate,
          amount: r.cr098_paymentamount,
          id: r.cr098_registrationrecordid,
          groupId: r.cr098_groupid,
          fullName: r.cr098_fullname,
          email: r.cr098_email,
          address: r.cr098_address,
          postalCode: r.cr098_postalcodes,
          paymentMode: r.teng_paymentmode,
          transactionId: r.teng_transactionid,
          grade: r.cr098_grade,
          digital:r.teng_digitalexam,
          instrument: r.cr098_instrumenttype,
          receiptId: r.teng_receipt_id,
          receiptReceiverName: r.teng_family_group_receipt_receiver_name,
          receiptReceiverEmail: r.teng_familygroupreceiptreceiveremail,
          test:'123123'
        }
      })
      }
  },
  filters: {
    payment(amount) {
      return amount?.toFixed(2) || "0.00";
    }
  },
};
</script>
<style lang="scss" scoped>

body {
  background-color: #fafafa;
  position: relative;
  height: 100%;
}
h4 {
  font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
  font-weight: bold;
  text-align: center;
}
.receipt-container {
  flex-direction: column;
  max-width: 750px;
  margin: auto;
  padding: 30px;
  border: 1px solid black;
  box-shadow: 0 0 20px rgb(0 0 1 / 30%);
  font-size: 17px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica";

  table{
    width: 100%;
    line-height: inherit;
    text-align: left;

    td {
      padding: 5px;
      vertical-align: top;
    }

    tr td:nth-child(n + 2) {
      text-align: right;
    }
    tr.top table td {
      padding-bottom: 20px;
    }
    tr.top table td.title {
      color: black;
    }
    tr.information table td {
      padding-bottom: 40px;
    }
    tr.heading td {
      background: #eee;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
    }
    tr.details td {
      padding-bottom: 20px;
    }
    tr.total td:nth-child(2) {
      border-top: 2px solid #eee;
      font-weight: bold;
    }
  }
}

.center {
  border-top: 2px solid #eee;
  text-align: center;
}

ul{
  margin-left: 10px;
}

li{
  list-style-type: "- ";
}

#title{
  margin-top: 225px;
}

#loadingIcon{
  left: 48%;
  border: 16px solid black;
  border-top: 16px solid lightgray;
}

 #errorPage{
  margin-top: 225px;

  h4{
    font-size: 35px;
  }

  div{
    font-size: 20px;
  }
}
</style>
