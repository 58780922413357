<template>
  <div id="app">
    <div class="test-banner no-print" v-if="$root.testenv"><strong>TEST ENVIRONMENT</strong></div>
    <router-view :class="{'top-case': $root.testenv}"/>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>

<style lang="scss">
.test-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: blue;
  color: white;
  font-size: medium;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}

.top-case {
  padding-top: 23px;
}

@media print {
  @page { size: auto;  margin: 0mm; }

  .no-print, .no-print * {
    display: none !important;
  }
}
.form-group legend{
    display: block;
    line-height: 1.5;
    font-size: .9em;
    font-weight: 600;
    margin-bottom: .1em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol

}
</style>