import registrationForm from '../views/registrationForm.vue'
import notFound from '../views/notFound.vue';
import receiptView from '../views/receiptView.vue';
import doneView from '../views/done.vue';
import manualNotificationsView from '@/views/manualNotifications.vue';
import emailView from '@/views/email.vue';
import VueRouter from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'registrationForm',
    component: registrationForm,
  },
  {
    path: '/receiptView/:id',
    name: 'receiptView',
    component: receiptView,
    // alias: ['/receiptView/:id']  
  },
  {
    path: '/done',
    name: 'done',
    component: doneView,
  },
  {
    path: '/manual-notifications',
    name: 'manual-notifications',
    component: manualNotificationsView,
  },
  {
    path: '/manual-notifications/email/:id',
    name: 'email',
    component: emailView
  },
  {
    path: '*',
    name: 'notFound',
    component: notFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router