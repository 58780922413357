<template lang="pug">
    div
        h4 {{_.yourRegistrationHasBeenSubmitted}}
        br
        p.p-4(v-html="_.ifYouHaveCompleted")
</template>

<script>
import translation from '@/assets/translation';

export default {
  name: 'doneView',
  computed: {
    _() {
      return translation[this.lang];
    }
  },
  mounted(){
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', this.lang);
    } else {
      this.lang = localStorage.getItem('lang');
    }
  },
  data() {
    return {
      lang: 'english'
    }
  },
  created() {
    let url = new URLSearchParams(window.location.href);
    if (!url.get('payment_intent_client_secret')) {
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss" scoped>

div{
  text-align: center;
  margin-top: 250px;

  h4{
    font-size: 35px;
  }

  p{
    font-size: 20px;
  }

}

</style>
