/**
 * Get cost of exam
 * @param {String} grade Grade level
 * @param {Number} type 1 - Normal,  2 - Digital
 */
export const getprice = (grade, type=1)=>{
    let amount = 0;
    if(type==1){
        switch(grade){
            case '895510000': amount += 60.00; break;
            case '895510001': amount += 95.00; break;
            case '895510002': amount += 110.00; break;
            case '895510003': amount += 150.00; break;
            case '895510004': amount += 195.00; break;
            case '895510005': amount += 240.00; break;
            case '895510006': amount += 280.00; break;
            case '895510007': amount += 335.00; break;
            case '895510008': amount += 495.00; break;
            default: amount = 0.00; break;
        }
    }
    if(type==2){
        switch(grade){
            case '895510000': amount += 40.00; break;
            case '895510001': amount += 80.00; break;
            case '895510002': amount += 95.00; break;
            case '895510003': amount += 130.00; break;
            case '895510004': amount += 160.00; break;
            case '895510005': amount += 205.00; break;
            case '895510006': amount += 250.00; break;
            case '895510007': amount += 295.00; break;
            case '895510008': amount += 495.00; break;
            default: amount = 0.00; break;
        }
    }

    return amount;

}
/**
 * Get postage fees
 * @param {Number} type 0 - local, 1 - bulk, 2 overseas
 */
export const get_postage = (type=0)=>{
    if(type==2){
        return 15.00;
    }
    return 0.00

}

