<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        class="m-2"
        :disabled="!emailData.teng_receipt_id"
        @click="openReceipt(emailData.cr098_registrationrecordid)"
      >
        {{
          emailData.teng_receipt_id
            ? "View Receipt"
            : "No receipt generated (Unpaid)"
        }}
      </b-button>
      <b-button class="m-2" @click="$router.go(-1)"> Back </b-button>
    </div>
    <div class="d-flex">
      <b-container fluid>
        <b-row class="my-1 align-items-center">
          <b-col sm="2">
            <label>Representative Name: </label>
          </b-col>
          <b-col sm="10">
            <p class="pb-1 mb-1">
              {{
                emailData.teng_family_group_receipt_receiver_name
                  ? emailData.teng_family_group_receipt_receiver_name
                  : "Not found"
              }}
            </p>
          </b-col>
        </b-row>

        <b-row class="my-1 align-items-center">
          <b-col sm="2">
            <label>Representative Email (To): </label>
          </b-col>
          <b-col sm="10">
            <b-form-input v-model="email.to" type="text"></b-form-input>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <label class="ml-3 mt-3">Email Body: </label>
    <ckeditor
      class="m-3"
      id="email-editor"
      v-model="email.body"
      :config="editorConfig"
    ></ckeditor>

    <div class="d-flex justify-content-end">
      <b-button class="mb-2 mr-3" :disabled="!email.to || sendingEmail" @click="sendEmail">
        {{sendingEmail ? 'Sending Email...' : 'Send Email'}}
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const receiptUrlBase = "https://registration.grading.thetengcompany.com/receiptView/";

export default {
  name: "emailView",
  data() {
    return {
      editorConfig: {
        height: "500px",
      },
      emailData: null,
      email: {
        subject: "Registration & Payment Successful - The TENG Company",
        to: null,
        body: "<p>Loading... Please wait.</p>",
      },
      sendingEmail: false
    };
  },
  async mounted() {
    if (!this.$store.state?.pw) {
      alert("Please access this link via the application");
      this.$router.push(`/manual-notifications${this.$router?.query?.test ? '?test=true': ''}`);
      return;
    }

    const pl = {
      pw: this.$store.state.pw,
      id: this.$route.params?.id,
      test: this.$root.testenv
    };

    let { data } = await axios.post("/api/email_details", pl);

    console.log(pl, data);
    this.emailData = data.value?.[0];

    this.email.body = this.getEmailBody();
    this.email.to = this.emailData.teng_familygroupreceiptreceiveremail
      ? this.emailData.teng_familygroupreceiptreceiveremail
      : this.emailData.cr098_email;
  },
  methods: {
    async sendEmail() {
      try {
        this.sendingEmail = true;
        let { data } = await axios.post("/api/send_email", {
          pw: this.$store.state.pw,
          email: this.email,
        });

        if (data.success) {
            alert('Email have been successfully sent!');
            this.sendingEmail = false;
        }
        console.log(data);
      } catch (e) {
        alert("Error: " + e.response.data);
      }
    },
    openReceipt(id) {
      window.open(`${receiptUrlBase}${id}${this.$root.testenv ? "?test=true" : ''}`, "_blank");
    },
    getEmailBody() {
      const contact = this.emailData;
      let link = '';
      
      if (contact.teng_receipt_id) {
        link = `${receiptUrlBase}${contact.cr098_registrationrecordid}${this.$root.testenv ? "?test=true" : ''}`;
      } else {
        link = '< No Receipt Generated (Unpaid) >';
      }

      const currentYear = new Date().getFullYear();

      const firstPart = `Thank you for submitting your registration for the Chinese Instrumental Music Grading Examination ${currentYear}, organised by The TENG Company in partnership with CI-NTU.<br/>
            You may view your receipt by visiting this link: <a href="${link}" target="_blank">${link}</a><br/><br/>`;

      const birthyears = {
        895510000: "2022",
        895510001: "2021",
        895510002: "2020",
        895510003: "2019",
        895510004: "2018",
        895510005: "2017",
        895510006: "2016",
        895510007: "2015",
        895510008: "2014",
        895510009: "2013",
        895510010: "2012",
        895510011: "2011",
        895510012: "2010",
        895510013: "2009",
        895510014: "2008",
        895510015: "2007",
        895510016: "2006",
        895510017: "2005",
        895510018: "2004",
        895510019: "2003",
        895510020: "2002",
        895510021: "2001",
        895510022: "2000",
        895510023: "1999",
        895510024: "1998",
        895510025: "1997",
        895510026: "1996",
        895510027: "1995",
        895510028: "1994",
        895510029: "1993",
        895510030: "1992",
        895510031: "1991",
        895510032: "1990",
        895510033: "1989",
        895510034: "1988",
        895510035: "1987",
        895510036: "1986",
        895510037: "1985",
        895510038: "1984",
        895510039: "1983",
        895510040: "1982",
        895510041: "1981",
        895510042: "1980",
        895510043: "1979",
        895510044: "1978",
        895510045: "1977",
        895510046: "1976",
        895510047: "1975",
        895510048: "1974",
        895510049: "1973",
        895510050: "1972",
        895510051: "1971",
        895510052: "1970",
        895510053: "1969",
        895510054: "1968",
        895510055: "1967",
        895510056: "1966",
        895510057: "1965",
        895510058: "1964",
        895510059: "1963",
        895510060: "1962",
        895510061: "1961",
        895510062: "1960",
        895510063: "1959",
        895510064: "1958",
        895510065: "1957",
        895510066: "1956",
        895510067: "1955",
        895510068: "1954",
        895510069: "1953",
        895510070: "1952",
        895510071: "1951",
        895510072: "1950",
        895510073: "1949",
        895510074: "1948",
        895510075: "1947",
        895510076: "1946",
        895510077: "1945",
        895510078: "1944",
        895510079: "1943",
        895510080: "1942",
        895510081: "1941",
        895510082: "1940",
        895510083: "1939",
        895510084: "1938",
        895510085: "1937",
        895510086: "1936",
        895510087: "1935",
        895510088: "1934",
        895510089: "1933",
        895510090: "1932",
        895510091: "1931",
        895510092: "1930",
        895510093: "1929",
      };
      const nationalities = [
        { value: 895510001, label: "Singapore" },
        { value: 895510000, label: "Malaysia" },
        { value: 895510002, label: "Indonesia" },
        { value: 895510003, label: "Others" },
      ];
      const nationality = nationalities.find(
        (n) => n.value == contact.cr098_nationality
      ).label;
      const finalNationality =
        nationality == "Others" ? contact.teng_nationalityothers : nationality;

      const instrumentTypes = {
        895510000: "Guzheng",
        895510001: "Erhu",
        895510002: "Dizi",
        895510003: "Pipa",
        895510004: "Yangqin",
        895510005: "Ruan",
        895510006: "Liuqin",
        895510007: "Gaoyin Sheng",
        895510008: "Zhongyin Sheng",
        895510009: "Gaoyin Suona",
        895510010: "Zhongyin Suona",
        895510011: "Cello",
        895510012: "Double Bass",
        895510013: "Hulusi",
        895510014: "Guanzi",
        895510015: "Percussion",
        895510016: "Guqin",
      };
      const instrument = instrumentTypes[contact.cr098_instrumenttype];
      const gradeOptions = {
        895510000: "Grade 1",
        895510001: "Grade 2",
        895510002: "Grade 3",
        895510003: "Grade 4",
        895510004: "Grade 5",
        895510005: "Grade 6",
        895510006: "Grade 7",
        895510007: "Grade 8",
        895510008: "Certificate In Professional Performance",
      };
      const grade = gradeOptions[contact.cr098_grade];
      const postageOption = [];
      if (contact.teng_bulkposting) {
        postageOption.push("Bulk Posting to Schools or Instructors");
      }

      if (contact.teng_localregisteredmail) {
        postageOption.push("Local Registered Mail");
      }

      if (contact.teng_overseasregisteredmail) {
        postageOption.push("Overseas Registered Mail");
      }

      const races = {
        895510000: "Chinese",
        895510001: "Malay",
        895510002: "Indian",
        895510003: "Others",
      };

      const midTablePart = `Your details:
            <style type="text/css">
            .tg  {border-collapse:collapse;border-spacing:0;}
            .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
              overflow:hidden;padding:10px 5px;word-break:normal;}
            .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
              font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
            .tg .tg-1wig{font-weight:bold;text-align:left;vertical-align:top}
            .tg .tg-0lax{text-align:left;vertical-align:top}
            </style>
            <table class="tg">
            <thead>
              <tr>
                <th class="tg-0lax"><span style="font-weight:bold"></span></th>
                <th class="tg-0lax"><span style="font-weight:bold">Submitted Information</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tg-0lax">Partial BC/NRIC/Passport No.</td>
                <td class="tg-1wig">${contact.cr098_partialic}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Full Name</td>
                <td class="tg-1wig">${contact.cr098_fullname}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Chinese Name</td>
                <td class="tg-0lax">${
                  contact.cr098_chinesename &&
                  contact.cr098_chinesename != "null"
                    ? contact.cr098_chinesename
                    : "-"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Gender</td>
                <td class="tg-0lax">${
                  contact.cr098_gender == "895510000" ? "Male" : "Female"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Year of Birth</td>
                <td class="tg-0lax">${birthyears[contact.cr098_birthyear]}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Nationality</td>
                <td class="tg-0lax">${finalNationality}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Race</td>
                <td class="tg-0lax">${races[contact.cr098_race]}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Email</td>
                <td class="tg-0lax">${contact.cr098_email}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Phone Number</td>
                <td class="tg-0lax">${
                  contact.cr098_phonenumber ? contact.cr098_phonenumber : "-"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Address</td>
                <td class="tg-0lax">${contact.cr098_address}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Postal Code</td>
                <td class="tg-0lax">${contact.cr098_postalcodes}</td>
              </tr>
              <tr>
                <td class="tg-0lax">School</td>
                <td class="tg-0lax">${
                  contact.cr098_school ? contact.cr098_school : "-"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Instructor</td>
                <td class="tg-0lax">${
                  contact.cr098_instructor ? contact.cr098_instructor : "-"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Do you have an Exam Book?</td>
                <td class="tg-0lax">${
                  contact.cr098_bookserialnumber != "null" &&
                  contact.cr098_bookserialnumber
                    ? 'Yes'
                    : 'No'
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Exam Book Serial Number</td>
                <td class="tg-0lax">${
                  contact.cr098_bookserialnumber != "null" &&
                  contact.cr098_bookserialnumber
                    ? contact.cr098_bookserialnumber
                    : "-"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Instrument</td>
                <td class="tg-0lax">${instrument}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Grade</td>
                <td class="tg-0lax">${grade}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Postage Option</td>
                <td class="tg-0lax">${
                  postageOption.length ? postageOption.join(", ") : "-"
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Payment Mode</td>
                <td class="tg-0lax">${contact.teng_paymentmode}</td>
              </tr>
              <tr>
                <td class="tg-0lax">Total Amount Paid</td>
                <td class="tg-0lax">S$${
                  contact.teng_amountwithoutpostagefee + contact.teng_postagefee
                }</td>
              </tr>
              <tr>
                <td class="tg-0lax">Transaction ID</td>
                <td class="tg-0lax">${contact.teng_transactionid}</td>
              </tr>
            </tbody>
            </table>`;

      const lastPart = `<br/><br/>
        For enquiries, please email <a href="mailto:grading@thetengcompany.com">grading@thetengcompany.com.</a>
        <br/><br/>
        The TENG Company<br/>
        1 Straits Boulevard<br/>
        #11-03E<br/>
        Singapore Chinese Cultural Centre<br/>
        Singapore 018906<br/>
        Attn: Chinese Instrumental Music Grading Examinations<br/><br/>
        Copyright © ${currentYear} <a href="https://thetengcompany.com/">The TENG Company</a>`;

      return `${firstPart}${midTablePart}${lastPart}`;
    },
  },
};
</script>

<style>
.yes{
    color: green;
}

.no {
    color: red;
}
</style>
