<template lang="pug">
div(:key="lang")
  h1(v-if="true") Registration is now closed.
  b-container(class="bv-example-row bv-example-row-flex-cols" v-else)
    br
    h2.p-2 {{_.title}}
    .d-flex.justify-content-end
      formulate-input(
        style="background: #fff;"
        v-model="lang"
        type="select"
        :options="{ english: 'English', chinese: '中文' }"
      )
    br
    div
      b-list-group
      ul#progressbar(v-if="page < 5")
        li#personal(
          :class="page === 1 ? 'active' : null"
          ) {{_.personal}}
        li#academic(
          :class="page === 2 ? 'active' : null"
          ) {{_.academic}}
        li#exam(
          :class="page === 3 ? 'active' : null"
          ) {{_.examBook}}
        li#payment(
          :class="page === 4 ? 'active' : null"
          ) {{_.examination}}
        li(
        :class="page === 0 ? 'clickable active' : 'clickable'"
        @click="page = 0"
        ) {{_.summary}}
      b-overlay(:show="!ready")
        formulate-form#form(#default="{ hasErrors }", @submit="submitForm")
          b-list-group-item#table-wrapper
            div
              TransitionGroup(name="slide")
                div(:key="page=0", v-if="page === 0")
                  h4 {{_.summary}}
                  p#summaryDetails {{_.youCanSubmit}}
                  b-alert(show dismissible)
                    .small {{_.yourRegistrationDetails}}
                  b-table(
                      :items='summaryTable.items' 
                      :fields='summaryTable.fields'
                      responsive
                    )
                    template(#cell(name)='row')
                      | {{ row.value.first }} {{ row.value.last }}
                    template(#cell(actions)='row')
                      b-button.mr-1(size='sm' @click='modifyEntry(row.item.id)')
                        | {{_.modify}} ✏️
                      b-button(size='sm' @click='removeEntry(row.item.id)')
                        | {{_.remove}} 🗑
                    template(#row-details='row')
                      b-card
                        ul
                          li(v-for='(value, key) in row.item' :key='key') {{ key }}: {{ value }}

                  <br/><br/><br/><br/><br/><br/><br/>
                  div#summary
                    br 
                    div(v-if="summaryTable.items.length >= 2")
                      formulate-input(
                        v-model="groupId"
                        type="select"
                        :label="_.familyOrGroupRegistration"
                        name="familyorGroup"
                        :placeholder="_.chooseAnOption"
                        :options="{ 895510000: _.family, 895510001: _.group }",
                        validation=""
                        validation-behavior="live"
                        ref="groupID"
                      )
                        template(#errors)
                          .formulate-input-error(v-if="!groupId") {{_.pleaseFillIn + _.familyOrGroupRegistration}}
                      
                      formulate-input(
                        v-model="familyOrGroupReceiptReceiverName",
                        type="text",
                        :name="_.familyOrGroupReceiptReceiverName",
                        :label="_.familyOrGroupReceiptReceiverName",
                        :placeholder="_.fullName",
                        validation="",
                        validation-behavior="live"
                      )
                      
                      formulate-input(
                        v-model="familyOrGroupReceiptReceiverEmail"
                        type="email"
                        :name="_.familyOrGroupReceiptReceiverEmail"
                        :label="_.familyOrGroupReceiptReceiverEmail"
                        validation=""
                        validation-behavior="live"
                      )
                        template(#errors)
                          .formulate-input-error(v-if="familyOrGroupReceiptReceiverEmail && !isValidEmail(familyOrGroupReceiptReceiverEmail)") {{_.isNotAValidEmail}}
                    br
                    h5 {{_.postageOption}}
                    formulate-input#localRegisteredMail.mb-0(
                      type="checkbox"
                      v-model="formdata.teng_localregisteredmail"
                      :label="_.localRegisteredMail"
                      name="localRegisteredMail"
                    )
                    formulate-input#overseasRegisteredMail.mb-0(
                      type="checkbox"
                      v-model="formdata.teng_overseasregisteredmail"
                      :label="_.overseasRegisteredMail"
                      name="overseasRegisteredMail"
                    ) 
                    //formulate-input#bulkPosting.mb-0(
                      type="checkbox"
                      v-model="formdata.teng_bulkposting"
                      :label="_.bulkPosting"
                      name="bulkPosting" )  


                    hr
                    formulate-input#checkAgreeInfo(
                      type="checkbox"
                      v-model="cr098_agreeinformation"
                      name="above term"
                      ref="agreeInfo"
                      :label="'<a href=\"https://www.thetengcompany.com/grading/terms-and-conditions\" target=\"_blank\">' + _.iWeAgree + '</a>'"
                      validation=""
                      error-behavior="live"
                    )
                      template(#label="{label}")
                        span(v-html="label")
                      template(#errors)
                        .formulate-input-error(v-if="!cr098_agreeinformation") {{_.pleaseAcceptTheAboveTerm}}
                    br 
                    formulate-input#checkAgreeUpdates(
                      type="checkbox"
                      v-model="cr098_getupdates"
                      :label="_.iWeWouldLike"
                      name="checkAgreeUpdates"
                      ref="agreeUpdates"
                    ) 
                      
                    
                    <br/><br/>
                    div.d-flex.justify-content-end 
                      b-button#addEntry(@click="addEntry") + {{_.addAnotherEntry}}
                      b-button#submitEntry(
                        v-if="summaryTable.items.length"
                        @click="checkout"
                        :disabled="hasErrors"
                        ) {{_.checkOut}}     
                
                div(:key="page=5", v-if="page === 5")
                    h4 {{_.payment}}
                    br(v-if="stripeLoading")
                    br(v-if="stripeLoading")
                    br(v-if="stripeLoading")
                    br(v-if="stripeLoading")
                    .loader(v-if="stripeLoading")
                    br
                    br
                    b-table(
                      :items="amountSummaryItems"
                      responsive
                    )
                    h4.text-center(v-if="!stripeLoading") {{_.dueNow}} 
                      strong(v-if="!stripeLoading") S${{paynow.amount}}
                    br
                    br
                    stripe-element-payment(
                      ref='paymentRef', 
                      :pk='stripe.pk', 
                      :elements-options='stripe.elementsOptions', 
                      :confirm-params='stripe.confirmParams'
                      @element-ready="stripeLoading = false"
                      @element-click="stripeLoading = true"
                    )
                    b-button.my-2(
                      block
                      v-if="!stripeLoading"
                      :disabled="stripeLoading || payClicked"
                      @click="pay") {{_.pay}}
                
                div(:key="page=1", v-if="page === 1")#page1
                  h4 {{_.personalDetails}}
                  hr
                  p(v-if="summaryTable.items.length")#summaryDetails 
                    | {{_.youCanSummary}} 
                  b-row(align-v="start")
                    b-col(:md="12" :xs="12")
                      formulate-input(
                        v-model="formdata.cr098_partialic",
                        type="text",
                        :name="_.nric",
                        :label="_.nric",
                        validation="",
                        :placeholder="_.lastFour",
                        maxlength=4,
                        validation-behavior="live"
                      )
                        template(#errors="{error}") 
                          .formulate-input-error(v-if="wentToPage2 && (!formdata.cr098_partialic || formdata.cr098_partialic.length !== 4)") {{_.partialIdRequired}}
                      br
                      formulate-input(
                        v-model="formdata.cr098_fullname",
                        type="text",
                        :name="_.fullName",
                        :label="_.fullName",
                        :placeholder="_.asInNric",
                        validation="",
                        validation-behavior="live"
                      )
                        template(#errors)
                          .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_fullname") {{_.pleaseFillIn + _.fullName}}
                      br
                      formulate-input(
                        v-model="formdata.cr098_chinesename",
                        type="text",
                        :label="_.chineseName",
                        name="chineseName",
                        validation=""
                      )
                      br
                      formulate-input(
                        v-model="formdata.cr098_gender",
                        type="select",
                        :label="_.gender",
                        :name="_.gender",
                        :placeholder="_.chooseAnOption",
                        :options="{ 895510000: _.male, 895510001: _.female }",
                        validation="",
                        validation-behavior="live"
                      )
                        template(#errors)
                            .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_gender") {{_.pleaseFillIn + _.gender}}
                      br
                      formulate-input(
                        v-model="formdata.cr098_birthyear",
                        type="select",
                        :label="_.yearOfBirth",
                        :placeholder="_.chooseAnOption",
                        :options="{ 895510000: '2022', 895510001: '2021', 895510002: '2020', 895510003: '2019', 895510004: '2018', 895510005: '2017', 895510006: '2016', 895510007: '2015', 895510008: '2014', 895510009: '2013', 895510010: '2012', 895510011: '2011', 895510012: '2010', 895510013: '2009', 895510014: '2008', 895510015: '2007', 895510016: '2006', 895510017: '2005', 895510018: '2004', 895510019: '2003', 895510020: '2002', 895510021: '2001', 895510022: '2000', 895510023: '1999', 895510024: '1998', 895510025: '1997', 895510026: '1996', 895510027: '1995', 895510028: '1994', 895510029: '1993', 895510030: '1992', 895510031: '1991', 895510032: '1990', 895510033: '1989', 895510034: '1988', 895510035: '1987', 895510036: '1986', 895510037: '1985', 895510038: '1984', 895510039: '1983', 895510040: '1982', 895510041: '1981', 895510042: '1980', 895510043: '1979', 895510044: '1978', 895510045: '1977', 895510046: '1976', 895510047: '1975', 895510048: '1974', 895510049: '1973', 895510050: '1972', 895510051: '1971', 895510052: '1970', 895510053: '1969', 895510054: '1968', 895510055: '1967', 895510056: '1966', 895510057: '1965', 895510058: '1964', 895510059: '1963', 895510060: '1962', 895510061: '1961', 895510062: '1960', 895510063: '1959', 895510064: '1958', 895510065: '1957', 895510066: '1956', 895510067: '1955', 895510068: '1954', 895510069: '1953', 895510070: '1952', 895510071: '1951', 895510072: '1950', 895510073: '1949', 895510074: '1948', 895510075: '1947', 895510076: '1946', 895510077: '1945', 895510078: '1944', 895510079: '1943', 895510080: '1942', 895510081: '1941', 895510082: '1940', 895510083: '1939', 895510084: '1938', 895510085: '1937', 895510086: '1936', 895510087: '1935', 895510088: '1934', 895510089: '1933', 895510090: '1932', 895510091: '1931', 895510092: '1930', 895510093: '1929' }",
                        validation="",
                        validation-behavior="live"
                      )
                        template(#errors)
                            .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_birthyear") {{_.pleaseFillIn + _.yearOfBirth}}
                      br 
                      formulate-input(
                        v-model="formdata.cr098_nationality"
                        type="select"
                        :label="_.nationality"
                        :name="_.nationality"
                        :placeholder="_.chooseAnOption"
                        :options="[{ value:895510001,label: _.singapore}, { value:895510000,label: _.malaysia}, { value:895510002,label: _.indonesia}, { value:895510003,label: _.others }]"
                        validation=""
                        validation-behavior="live"
                      )
                        template(#errors)
                            .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_nationality") {{_.pleaseFillIn + _.nationality}}
                      br
                      formulate-input(
                        v-model="formdata.teng_nationalityothers",
                        type="text",
                        :label="_.nationalityOthers",
                        :name="_.nationalityOthers",
                        v-if="formdata.cr098_nationality === '895510003'"
                      )
                      br 
                    b-col(:md="12" :xs="12")
                      formulate-input(
                        v-model="formdata.cr098_race"
                        :label="_.race"
                        type="select"
                        :placeholder="_.chooseAnOption"
                        :options="{ 895510000: _.chinese, 895510001: _.malay, 895510002: _.indian, 895510003: _.others }"
                        validation="",
                        validation-behavior="live"
                      )
                        template(#errors)
                            .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_race") {{_.pleaseFillIn + _.race}}
                      br
                      formulate-input(
                        v-model="formdata.cr098_email"
                        type="email"
                        :name="_.email"
                        :label="_.email"
                        validation=""
                        validation-behavior="live"
                      )
                        template(#errors)
                          .formulate-input-error(v-if="wentToPage2 && !isValidEmail(formdata.cr098_email)") {{_.isNotAValidEmail}}
                      br
                      formulate-input(
                        type="tel"
                        v-model="formdata.cr098_phonenumber"
                        :label="_.phoneNumber"
                        maxlength=15
                        validation=""
                        validation-behavior="live"
                      )
                        template(#errors)
                          .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_phonenumber") {{_.phoneNumberRequired}}
                          .formulate-input-error(v-if="wentToPage2 && !/^[- +()]*[0-9][- +()0-9]*$/.test(formdata.cr098_phonenumber)") {{_.phoneNumberMustBeANumber}}
                          .formulate-input-error(v-if="wentToPage2 && formdata.cr098_phonenumber.length < 7 || formdata.cr098_phonenumber.length > 15") {{_.phoneNumberMustBe7}}
                      br
                      formulate-input(
                        v-model="formdata.cr098_address"
                        :label="_.address"
                        type="textarea"
                        :name="_.address"
                        row="2"
                        minlength=10
                        validation=""
                        validation-behavior="live"
                      )
                        template(#errors)
                            .formulate-input-error(v-if="wentToPage2 && !formdata.cr098_address") {{_.addressIsRequired}}
                            .formulate-input-error(v-if="wentToPage2 && formdata.cr098_address.length < 11") {{_.addressMustBe10}}
                      br
                      formulate-input(
                        v-model="formdata.cr098_postalcodes",
                        type="text"
                        :label="_.postalCode"
                        :name="_.postalCode"
                        validation=""
                        validation-behavior="live"
                        maxlength=10
                      ) 
                        template(#errors)
                          .formulate-input-error(v-if="wentToPage2 && (!formdata.cr098_postalcodes || formdata.cr098_postalcodes.length < 5)") {{_.postalCodeMustBe5}}
                      br

                div(:key="page=2", v-else-if="page === 2")
                  h4 {{_.academicDetails}}
                  hr
                  formulate-input(
                    v-model="formdata.cr098_school"
                    type="text"
                    :name="_.school"
                    :label="_.school"
                    validation="^optional"
                    validation-behavior="live"
                  )

                  formulate-input(
                    v-model="formdata.cr098_instructor"
                    type="text"
                    name="Instructor's Name"
                    :label="_.instructor"
                    :help="_.instructorsNameWill"
                    validation="^optional"
                    validation-behavior="live"
                  )
                  formulate-input(
                    v-model="formdata.teng_instructoremail"
                    type="email"
                    name="Instructor's Name"
                    :label="_.instructorEmail"
                    :help="_.instructorsEmailInst"
                    validation="^optional"
                    validation-behavior="live"
                  )
                  br
                div(:key="page=3", v-else-if="page === 3")
                  h4 {{_.examBookDetails}}
                  hr
                  #promptExamBookDetails
                    formulate-input(
                      v-model="checkBook"
                      type="select"
                      :label="_.doYouHaveAnExamBook"
                      :placeholder="_.chooseAnOption"
                      :options="[ { label: _.yes, value: 'true' }, { label: _.no, value: 'false' }, ]"
                      validation=""
                      validation-behavior="live"
                      ref="examDetailsGrade"
                    )
                    <br/>
                  TransitionGroup(name="slide")
                    p#getLink(:key="0", v-if="checkBook === 'false'")
                      | <b><a href="https://www.thetengcompany.com/grading/buy-music-scores" target="_blank" rel="noreferrer noopener">{{_.youCanVisit}}</a></b>
                      <br/><br/><br/>
                    p(:key="1", v-else)
                      formulate-input(
                        v-model="formdata.cr098_bookserialnumber"
                        type="text"
                        :label="_.examBookSerialNumber"
                        :name="_.examBookSerialNumber"
                        validation=""
                        validation-behavior="live"
                        :help="_.egSerial"
                        maxlength=11
                        minlength=10
                        ref="ExamBookSerial"
                      )
                        template(#errors)
                          .formulate-input-error(v-if="wentToPage4 && checkBook && !formdata.cr098_bookserialnumber") {{_.bookSerialNumberIsRequired}}
                          .formulate-input-error(v-if="wentToPage4 && checkBook && formdata.cr098_bookserialnumber.length != 10 && formdata.cr098_bookserialnumber.length != 11") {{_.bookSerialNumberMustBe10}}
                      br
                div(:key="page=4", v-else-if="page === 4")
                  h4 {{_.examDetails}}
                  hr
                  formulate-input#instrumentTypeOptions(
                    :label="_.instrument"
                    type="select"
                    v-model="formdata.cr098_instrumenttype"
                    :options="instrumentTypeOptions"
                    :placeholder="_.chooseAnOption"
                    validation=""
                    validation-behavior="live"
                    ref="examDetailsInstrument"
                  )
                    template(#errors)
                      .formulate-input-error(v-if="wentToPage5 && !formdata.cr098_instrumenttype") {{_.instrumentIsRequired}}
                  formulate-input(
                    v-model="formdata.cr098_grade"
                    type="select"
                    :label="_.grade"
                    :placeholder="_.chooseAnOption"
                    :options="gradeOptions"
                    validation=""
                    validation-behavior="live"
                    ref="examDetailsGrade"
                  )
                    template(#errors)
                      .formulate-input-error(v-if="wentToPage5 && !formdata.cr098_grade") {{_.gradeIsRequired}}
                  b-form-group(:label="_.typeExam")
                    b-select(
                      v-model="formdata.teng_digitalexam"
                      :options="typeExam"
                    )
                  
                  div(v-if="formdata.cr098_grade == '895510008'")
                    b-form-group.required(:label="_.grade8CertOr")
                      template(#description)
                        p {{_.pleaseUploadYourCertificate}}
                      b-form-file(name="certfile1" required v-model="certfile1")
                    b-form-group.required(:label="_.moreFiles")
                      b-form-file(name="certfile2" v-model="certfile2")
                      b-form-file(name="certfile3" v-model="certfile3")
                  div
                    <b><a href="https://www.thetengcompany.com/grading/registration-fees-payment" target="_blank" rel="noreferrer noopener">{{_.youCanVisit2}}</a></b>

                  <br/><br/>
                div#loadingScreen(:key="page=6", v-else-if="page === 6")
                  b-form-group(:label="_.submittingRegistrationForm")
                  b-form-group(:label="_.pleaseWaitForAMinute")
                  b-form-group(
                    :label="_.doNotCloseYourBrowser"
                  )
                  <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                  .loader

              div#loadingScreen(v-if="submitFormSuccess && page === 7")
                b-alert(variant="success", show) Success! <br/>
                b-form-group(label="Submission is successful.")
                b-form-group(label="You can now close the tab or your browser.")
                lottie-player#successIcon(
                  autoplay,
                  loop,
                  mode="normal",
                  src="https://assets4.lottiefiles.com/packages/lf20_lk80fpsm.json"
                )
                <br/>
                a(href=".") Make another registration?
                <br/>
                <br/>
                router-link(:to="`/receiptView/${receiptId}`") View Receipt

              .mb-5.buttons 
                b-button#cancel( @click="cancelStep", v-if="page < 4  && page >0 && summaryTable.items.length", type="button") {{_.cancel}}
                b-button#previous( @click="prevPage", v-if="page > 1 && page < 5", type="button" ) {{_.previous}}
                b-button#next( @click="nextStep", v-if="page < 4 && page !== 0", type="submit") {{_.next}}
                b-button#submit( @click="confirmSubmitForm", v-if="page === 4 && !modifying", type="submit") {{_.addRegistration}}
                b-button#next(@click="modifyForm", v-if="page === 4 && modifying", type="button") {{_.modify}} 

</template>

<script>
import axios from "axios";
import PaynowQR from 'paynowqr';
import VueQr from 'vue-qr';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import translation from '@/assets/translation';
import { getprice } from '../../getprice.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault('Asia/Singapore')

export default {
  name: "App",
  components: {
      VueQr,
      StripeElementPayment,
  },
  data() {
    return {
      payClicked: false,
      lang: 'english',
      modifyingId: null,
      stripeLoading: true,
      ready: false,
      modifyOriginal:"",
      //summary page
      qrLogo: '/paynow.png',
      QRvalue: null,
      receiptId: "",
      submitting: false,
      checkBook: 'false',
      submitFormSuccess: true,
      checkGroup: "false",
      modifying: false,
      page: 1,
      groupId: null,
      groupOrFamily: null,
      familyOrGroupReceiptReceiverName: '',
      familyOrGroupReceiptReceiverEmail: '',
      formdata: {
        id: null,
        //Academic Details
        cr098_instructor: "",
        teng_instructoremail:"",
        cr098_school: "",
        cr098_bookserialnumber: "",
        cr098_instrumenttype: null,
        cr098_grade: null,

        //Registration Record (Personal Details)
        cr098_email: "",
        cr098_birthyear: null,
        cr098_nationality: "",
        teng_nationalityothers: "",
        cr098_partialic: "",
        cr098_chinesename: "",
        cr098_fullname: "",
        cr098_phonenumber: "",
        cr098_postalcodes: "",
        cr098_address: "",
        cr098_race: "",
        cr098_gender: "",
        cr098_groupid: "",
        
        //receipt
        cr098_companyuen: "",
        cr098_paymentcompanyname: "",
        cr098_paymentamount: "",
        cr098_receiptdate: "",
        cr098_referencenumber: "",

        teng_localregisteredmail: false,
        teng_overseasregisteredmail: false,
        teng_bulkposting: false,
        teng_digitalexam:false
      },
      cr098_agreeinformation: false,
      cr098_getupdates: false,
      paynow: { //PayNow QR Code
        uen:'201502077Z',          
        amount : 0.00,                                   
        refNumber: "",   
        company:  'THE TENG COMPANY LTD'  
      },
      elements: null,
      stripe: {
        pk: "pk_live_d3NAvY3XXEmBnxDL0gGp6oTZ009wNzFAbT",
        elementsOptions: {
          appearance: {}, // appearance options
          clientSecret: '',
        },
        confirmParams: {
          return_url: window.location.hostname.toLowerCase() === 'localhost' ? 'http://localhost:8080/done/' : 'https://registration.grading.thetengcompany.com/done/', // success url
        },
      },
      allRecordsObj: {
        groupId: null,
        records: []
      },
      certfile1: null,
      certfile2: null,
      certfile3: null,
      wentToPage2: false,
      wentToPage4: false,
      wentToPage5: false
    }
  },
  mounted(){
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', this.lang);
    } else {
      this.lang = localStorage.getItem('lang');
    }

    this.loadPreviously();

    if (this.$root.testenv) {
      this.stripe.pk = 'pk_test_hzpnjbLnGaCSRdZvE6m5sWTh00sLXFOfYN'
    }
    
    this.ready = true;
  },
  methods: {
    getPostageFee(row) {
      let selectedPostageOption = {
        type: null,
        amount: null
      };

      if (row.teng_localregisteredmail) {
        selectedPostageOption.type = 'local';
        selectedPostageOption.amount = 0;
      } else if (row.teng_overseasregisteredmail) {
        selectedPostageOption.type = 'overseas';
        selectedPostageOption.amount = 15;
      } else if (row.teng_bulkposting) {
        selectedPostageOption.type = 'bulk';
        selectedPostageOption.amount = 0;
      }

      return selectedPostageOption;
    },
    getRowAmount(grade, priceType=1) {
      grade = grade+ '';
      let amount = 0;
          amount += getprice(grade,priceType);
      return amount.toFixed(2);
    },
    isValidEmail(email) {
      // eslint-disable-next-line no-control-regex
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email)
    },
    loadPreviously(){
      try{
        let autosave =  JSON.parse(localStorage.getItem("teng-autosave"));

        if(autosave.length){
          let r = confirm(this._.loadPreviously)
          if(r){
            this.summaryTable.items = autosave;
            this.page = 0;
          }
        }
      }catch{
        //do nothing;
      }
    },
    modifyForm(){
      if(!this.formdata.cr098_instrumenttype || !this.formdata.cr098_grade){
          alert(this._.pleaseEnterAllNecessary);
          this.page = 4; 
          return;
      }

      if(this.formdata.cr098_grade == '895510008' && !this.certfile1) {
        alert (this._.pleaseUploadYourCertificate);
        return;
      }

      this.saveForm(this.modifyingId);

      this.page = 0
    },
    async generatePaymentIntent() {
      const summaryTableClone = {...this.summaryTable};
      summaryTableClone.items = summaryTableClone.items.map((c) => {
        delete c.certfile1;
        delete c.certfile2;
        delete c.certfile3;
        return {
          ...c
        }
      });
      
      const payload = {
        summaryTable: summaryTableClone,
        allRecordsObj: this.allRecordsObj,
        test: this.$root.testenv
      }
      const {data} = await axios.post(`/api/payment`, payload);
      console.log(data)
      const paymentIntent = data.paymentIntent;
      this.stripe.elementsOptions.clientSecret = paymentIntent.client_secret;
    },
    pay () {
      this.payClicked = true;
      this.$refs.paymentRef.submit();
    },
    async isEmailTaken(){
      //const emailInput = this.formdata.cr098_email.trim().toLowerCase();
      //this.ready = false;
      //try {
        // const {data} = await axios.get(`/api/bookSerial?email=${emailInput}`);
        // this.ready = true;
        // if (data.value && data.value.length) {
        //   return true;
        // }
        return false;
      // }
      // catch (e){
      //   alert("Unable to connect to the system. Please try your submission another time.");
      //   return false;
      // }
    
    },
    async isBookSerialTaken(){
      return false;
      // const bookSerialInput = this.formdata.cr098_bookserialnumber.trim();
      // this.ready = false;
      // try {
      //   const {data} = await axios.get(`/api/bookSerial?bookSerial=${bookSerialInput}`);
      //   this.ready = true;
      //   if (data.value && data.value.length) {
      //     return true;
      //   } 
      // }
      // catch (e){
      //     alert(this._.unableToSubmit);
      //     return false;
      //   }
    },
    checkIcInput(){
      // const checkIc1 = this.formdata.cr098_partialic.slice(0, 3);
      // const checkIc2 = this.formdata.cr098_partialic.slice(3, 4);
      // if(!Number.isInteger(checkIc1) && !checkIc2.match(/^[A-Za-z]+$/)){
      //   alert("This IC number is not valid.");
      //   this.page = 1;
      //   return false;
      // }
      return true;
    },
    cancelStep(){
      let r = confirm(this._.sureCancel);
      if(!r){
        return;
      }
      
      if(this.modifying){
        let ori = JSON.parse(this.modifyOriginal);
        Object.assign(this.formdata,ori);
      }
      this.modifying = false;
      this.modifyOriginal =  "";
      this.clearForm();
      this.page = 0;
    },
    async prevPage() {
      this.page = this.page - 1;
    },
    async nextStep(e) {
      if (this.page < 6) {
        e.preventDefault();
        await this.nextPage();
      }
    },
    async nextPage(e) { 
      let inputError = document.getElementsByClassName("formulate-input-error");

      if((this.page === 1 || this.page === 2 ||this.page === 4) && inputError.length >= 1 ){
        alert(this._.pleaseEnterAllNecessary);
        return;
      }

      if (this.page === 1) {
        this.wentToPage2 = true;
        if (!this.formdata.cr098_race) {
          alert(this._.pleaseFillIn + this._.race);
          return;
        }

        if (!this.formdata.cr098_email) {
          alert(this._.pleaseFillIn + this._.email);
          return;
        }

        if (!this.formdata.cr098_fullname) {
          alert(this._.pleaseFillIn + this._.fullName);
          return;
        }
        if (!this.formdata.cr098_gender) {
          alert(this._.pleaseFillIn + this._.gender);
          return;
        }

        if (!this.formdata.cr098_birthyear) {
          alert(this._.pleaseFillIn + this._.yearOfBirth);
          return;
        }

        if (!this.formdata.cr098_postalcodes) {
          alert(this._.pleaseFillIn + this._.postalCode);
          return;
        }

        if (!this.formdata.cr098_nationality) {
          alert(this._.pleaseFillIn + this._.nationality);
          return;
        }

        if (this.formdata.cr098_nationality === "895510003" && !this.formdata.teng_nationalityothers) {
          alert(this._.pleaseFillIn + this._.nationality);
          return;
        }

        if (!this.formdata.cr098_address) {
          alert(this._.pleaseFillIn + this._.address);
          return;
        }


      }

    
      
      if(this.page === 1){
        if(this.checkIcInput() && inputError.length === 0 ){
          const emailTaken = await this.isEmailTaken();
          
          if (emailTaken) {
            alert("You have already registered with this email. If you need to adjust your registration, please contact us directly.");
            this.page = 1;
            return;
          }
        
          this.page = 2;
        }
        else{
          this.page = 1;
        }

      }
      else if(this.page === 3 && this.checkBook == 'false'){
          this.wentToPage4 = true;
          this.page = this.page + 1;
      }
      else if(this.page === 3 && this.checkBook == 'true'){

        const BookSerialTaken = await this.isBookSerialTaken();
        let bookSerialInput = this.formdata.cr098_bookserialnumber.trim();
        if(bookSerialInput.slice(0,1) == "J" && bookSerialInput.length == 11){
          bookSerialInput = bookSerialInput.slice(-10);
        }

        const instumentCode = bookSerialInput ? bookSerialInput.slice(0, 2) : null;
        const yearCode = bookSerialInput ? +bookSerialInput.slice(2, 4) : null;
        const volumeCode = bookSerialInput ? bookSerialInput.slice(4, 6) : null;
        const sequenceCode1 = bookSerialInput ? +bookSerialInput.slice(-4, -1) : null;
        const sequenceCode2 = bookSerialInput ? bookSerialInput.slice(-1) : null;
        const volume = ['10','20','30','D0'];

        if(!this.formdata.cr098_bookserialnumber && this.checkBook === 'true'){
          alert(this._.pleaseEnterAllNecessary);
          this.page = 3;
          return;
        }
        
        if (BookSerialTaken) {
          alert(this._.thisBookSerialNumber);
          this.page = 3;   
          return;
        }
        else if(instumentCode){
          const instrument = this.instrumentType[instumentCode]? this.instrumentType[instumentCode]: null;
          const result = instrument ? Object.keys(this.instrumentTypeOptions).find((key) => this.instrumentTypeOptions[key] === instrument): null;
          result ? (this.formdata.cr098_instrumenttype = result) : null; 
          
          if( (result === null || !volume.includes(volumeCode)) || yearCode < 17  || (result != 895510015) && (volumeCode == 'D0') || !Number.isInteger(sequenceCode1) || !sequenceCode2.match(/^[A-Z]+$/)){
            alert(this._.thisSerialNumber);
            return;
          }
        }  
        this.page = 4;
      }
      else if (this.page === 4) {
        this.wentToPage5 = true;
        e.preventDefault(); 
        return;
      }
      else{

        this.page = this.page + 1;
      }
    },
    //modify one of the entries for the summary table
    modifyEntry(id){ 
      const entry = this.summaryTable.items.find(f => f.id === id);
      if (!entry) {
        return;
      }
      
      this.modifyingId = id;
      this.formdata = entry;
      this.modifying = true;
      this.modifyOriginal = JSON.stringify(entry);
      this.page = 1;
    },
    //remove one of the entries from the summary table
    removeEntry(id) {
      const result = confirm(this._.sureRemove);
      if (!result) {
        return;
      }
      this.summaryTable.items = this.summaryTable.items.filter(f => f.id !== id);
      localStorage.setItem("teng-autosave", JSON.stringify(this.summaryTable.items));
    },
    //add an entry
    addEntry(){
      this.modifying = false;
      this.clearForm();
      this.wentToPage2 = this.wentToPage4 = this.wentToPage5 = false;
      this.page = 1;
    },
    saveForm(existing = null) {
      const pl = {...this.finalData};

      

      const finalData = JSON.parse(JSON.stringify(pl));
      if (!existing) {
        finalData.id = Math.random().toString(16).slice(2);

        if (this.formdata.cr098_grade == '895510008') {
          if (this.certfile1) {
            finalData.certfile1 = this.certfile1;
          }

          if (this.certfile2) {
            finalData.certfile2 = this.certfile2;
          }

          if (this.certfile3) {
            finalData.certfile3 = this.certfile3;
          }
        }

        this.summaryTable.items.push(finalData);
      } else {
        const index = this.summaryTable.items.findIndex(i => i.id === existing);
        this.$set(this.summaryTable.items, index, finalData);
      }
      localStorage.setItem("teng-autosave", JSON.stringify(this.summaryTable.items));
      this.clearForm();
      this.page = 0; 

    },
    clearForm(){
      this.formdata = {
        id: null,
        //Academic Details
        cr098_instructor: "",
        teng_instructoremail:"",
        cr098_school: "",
        cr098_bookserialnumber: "",
        cr098_instrumenttype: null,
        cr098_grade: null,

        //Registration Record
        cr098_email: "",
        cr098_birthyear: null,
        cr098_nationality: "",
        teng_nationalityothers: "",
        cr098_partialic: "",
        cr098_chinesename: "",
        cr098_fullname: "",
        cr098_phonenumber: "",
        cr098_postalcodes: "",
        cr098_address: "",
        cr098_race: "",
        cr098_gender: "",
        cr098_agreeinformation: false,
        cr098_getupdates: false,
        cr098_groupid: "",
        cr098_referencenumber: "",
        cr098_companyuen: "",
        cr098_paymentcompanyname: "",
        cr098_paymentamount: "",
        cr098_receiptdate: "",
        teng_localregisteredmail: false,
        teng_overseasregisteredmail: false,
        teng_bulkposting: false,
        teng_digitalexam:false,
        teng_family_group_receipt_receiver_name: '',
        teng_familygroupreceiptreceiveremail: ''
      };

      this.certfile1 = this.certfile2 = this.certfile3 = null;
      this.familyOrGroupReceiptReceiverEmail = this.familyOrGroupReceiptReceiverName = '';
    },
    async submitForm() {
      //const proceed = confirm("Are you sure you want to submit the form?");
      const proceed = true;
      if(proceed){
        this.submitting = true;
        this.page = 6;
        const isGroup = this.summaryTable.items.length >= 2;
        const groupId = isGroup ? this.groupOrFamily ==  895510000 ? 'F' + Math.random().toString(16).slice(2) : 'G' + Math.random().toString(16).slice(2): null;
        const finalArr = [...this.summaryTable.items];
        console.log(finalArr)
        for (let form of finalArr) {
          try {
            delete form.id;
            isGroup ? form.cr098_groupid = groupId : null;
            this.checkBook === 'false' ? form.cr098_bookserialnumber = '' : null;

            form.cr098_referencenumber = this.paynow.refNumber;
            form.cr098_companyuen = this.paynow.uen ;
            form.cr098_paymentcompanyname = this.paynow.company;
            form.cr098_paymentamount = this.paynow.amount;
            form.cr098_referencenumber = this.paynow.refNumber;
            form.cr098_receiptdate = dayjs().tz("Asia/Singapore").utc().format();

            form.cr098_getupdates = this.cr098_getupdates;
            form.cr098_agreeinformation = this.cr098_agreeinformation;

            form.teng_localregisteredmail = this.formdata.teng_localregisteredmail;
            form.teng_overseasregisteredmail = this.formdata.teng_overseasregisteredmail;
            form.teng_bulkposting = this.formdata.teng_bulkposting;
            form.teng_family_group_receipt_receiver_name = this.familyOrGroupReceiptReceiverName;
            form.teng_familygroupreceiptreceiveremail = this.familyOrGroupReceiptReceiverEmail;

            let fd = new FormData();

            for(let key in form){
              // if(!form[key]){
              //   continue;
              // }
              fd.append(key, form[key]);
            }

            const {data} = await axios.post(`api/form_api${this.$root.testenv ? '?test=true' : ''}`, fd, {headers: {
              'Content-Type': 'multipart/form-data'
            }});
            if (!data.success) {
              throw "Submission issue";
            }

            if(groupId){
              this.receiptId = data.contact.cr098_groupid;
              this.allRecordsObj.groupId = groupId;
            }
            else{
              this.receiptId = data.contact.cr098_registrationrecordid;
            }

            this.allRecordsObj.records.push({
              id: data.contact.cr098_registrationrecordid,
              email: data.contact.cr098_email,
              amount: +this.getRowAmount(data.contact.cr098_grade, data.contact.teng_digitalexam ? 2 : 1) + +this.getPostageFee(data.contact).amount,
              postageFee: +this.getPostageFee(data.contact).amount,
              amountWithoutPostageFee: +this.getRowAmount(data.contact.cr098_grade, data.contact.teng_digitalexam ? 2 : 1)
            });

            this.allRecordsObj.familyOrGroupReceiptReceiverName = this.familyOrGroupReceiptReceiverName;
            this.allRecordsObj.familyOrGroupReceiptReceiverEmail = this.familyOrGroupReceiptReceiverEmail;
            
          } catch (e) {
            alert(this._.unableToSubmit);
            this.submitFormSuccess = false;
            this.page = 0;

            if(this.summaryTable.items.length >= 2){
              let result = {};
              result = await axios.delete(`api/form_api${this.$root.testenv ? '?test=true' : ''}`, form);
              console.log(result);
            }
            break;
          }
        }



        // this.submitFormSuccess = true;
        // localStorage.removeItem("teng-autosave");

        await this.generatePaymentIntent();
        this.page = 5;
        this.submitting = false;
        // this.summaryTable.items = [];
        // this.clearForm();
  
        // alert("Submission of the Record is Successful.");
      }
      else{
        this.page = 0;
      }
    },
    confirmSubmitForm(e) {
      if(this.formdata.cr098_grade == '895510008' && !this.certfile1) {
        alert (this._.pleaseUploadYourCertificate);
        return;
      }

      if(!this.formdata.cr098_instrumenttype || !this.formdata.cr098_grade){
          alert(this._.pleaseEnterAllNecessary);
          this.page = 4; 
          return;
      }
      if(!confirm(this._.areYouSureYouWant)) {
        e.preventDefault();
        return;
      }

      this.saveForm();
  
    },
    async checkout(e){
      if(this.page === 0){
        if (confirm(this._.areYouSureYouWantToCheckOut) == true) {

          if (!this.formdata.teng_localregisteredmail && 
          !this.formdata.teng_overseasregisteredmail && 
          !this.formdata.teng_bulkposting) {
            alert(this._.pleaseFillIn + this._.postageOption);
            return;
          }

          if (!this.$refs.agreeInfo.$options.propsData.formulateValue) {
            alert(this._.youMustAgree);
            e.preventDefault();
            return;
          } else if (this.$refs.agreeInfo.$options.propsData.formulateValue) {
            e.preventDefault();
            if(this.summaryTable.items.length >= 2){
              this.groupOrFamily = this.$refs.groupID.$options.propsData.formulateValue;


              if (!this.groupOrFamily) {
                alert(this._.pleaseFillIn + this._.familyOrGroupRegistration);
                e.preventDefault();
                return;
              }

              if (!this.familyOrGroupReceiptReceiverName) {
                alert(this._.pleaseFillIn + this._.familyOrGroupReceiptReceiverName);
                e.preventDefault();
                return;
              }

              if (!this.familyOrGroupReceiptReceiverEmail) {
                alert(this._.pleaseFillIn + this._.familyOrGroupReceiptReceiverEmail);
                e.preventDefault();
                return;
              }

              //GZ2210001C, GZ2230002C, GZ2230001C (book serial for checking)
              const uniqueBooksAmount = new Set(this.bookSerialArr).size;

              //If the book serial is same and is not family then alert this.
              if (uniqueBooksAmount !== this.bookSerialArr.length && this.groupOrFamily ==  895510001) {
                alert(this._.bookSerialNumber);
                e.preventDefault();
                this.page = 0;
                return;
              }

            }
           
            this.createQr();
            try {
              await this.submitForm();
            } catch (error) {
              return;
            }
            
            localStorage.removeItem("teng-autosave");
            
          }
        }
      }
    },
    //Generate QR code function
    createQr(){
      this.paynow.refNumber = `EXAM${dayjs().format("YYMMDDHHmmss")}${String(Math.floor(Math.random()*99)).padStart(2,'0')}`
      this.paynow.amount =0;
      for(let i = 0; i < this.checkPrice.length; i++){
          let isDigital = this.checkPrice[i].digital;
          if(typeof isDigital == 'string'){
            isDigital = JSON.parse(isDigital);
          }
          this.paynow.amount += getprice(this.checkPrice[i].grade,isDigital? 2 : 1 );
      }

      //postage

      if (this.formdata.teng_localregisteredmail) {
        for (let person in this.summaryTable.items) {
          console.log(person)
          this.paynow.amount += 0.00;
        }
      }

      if (this.formdata.teng_overseasregisteredmail) {
        for (let person in this.summaryTable.items) {
          console.log(person)
          this.paynow.amount += 15.00;
        }
      }

      //Generate QR Code 
      let qrcode = new PaynowQR({
          uen: this.paynow.uen,         
          amount : this.paynow.amount,        
          refNumber: this.paynow.refNumber,  
          company:  this.paynow.company              
        });

      this.QRvalue = qrcode.output();
    },
    reducer(opt) {
      return opt.code || opt.value || false;
    },
  },
  watch: {
    'formdata.teng_localregisteredmail'(val) {
      if (val) {
        this.formdata.teng_overseasregisteredmail = false;
        this.formdata.teng_bulkposting = false;
      }
    },
    'formdata.teng_overseasregisteredmail'(val) {
      if (val) {
        this.formdata.teng_localregisteredmail = false;
        this.formdata.teng_bulkposting = false;
      }
    },
    'formdata.teng_bulkposting'(val) {
      if (val) {
        this.formdata.teng_localregisteredmail = false;
        this.formdata.teng_overseasregisteredmail = false;
      }
    },
    lang(val) {
      localStorage.setItem('lang', val);
      
      this.loadPreviously();
      
    },
    certfile1(){
      if(this.certfile1 && this.certfile1.size > 10000000){
        alert(this._.pleaseUploadYourCertificate);
        setTimeout(()=>{
          this.certfile1 = null;
        },500);
      }
    },
    certfile2(){
      if(this.certfile2 && this.certfile2.size > 10000000){
        alert(this._.pleaseUploadYourCertificate);
        setTimeout(()=>{
          this.certfile2 = null;
        },500);
      }
    },
    certfile3(){
      if(this.certfile3 && this.certfile3.size > 10000000){
        alert(this._.pleaseUploadYourCertificate);
        setTimeout(()=>{
          this.certfile3 = null;
        },500);
      }
    }
  },
  computed: {
    amountSummaryItems(){

      return this.summaryTable.items.map(i => {
        console.log(i)


      const selectedPostageOption = this.getPostageFee(i);
      const isDigital = i.teng_digitalexam === true ||i.teng_digitalexam ==="true" ;

      const rowAmount = this.getRowAmount(i.cr098_grade,isDigital ? 2 : 1 );
      console.log(rowAmount)
        return {
          [this._.fullName]: i.cr098_fullname,
          [this._.grade]: this.grades[i.cr098_grade],
          [this._.typeExam]:isDigital ? this._.digitalexam : this._.facetoface,
          [this._.price]: rowAmount,
          [this._.postageFee]: selectedPostageOption.amount.toFixed(2),
          [this._.total]: (+rowAmount + selectedPostageOption.amount).toFixed(2)
        }
      });
    },
    _(){
      return translation[this.lang]
    },
    summaryTable() {
        return {
          items: [
        ],
        fields: [
          { key: 'cr098_fullname', label: this._.fullName, sortable: true, sortDirection: 'desc' },
          { key: 'cr098_partialic', label: this._.nric, sortable: true, },
          { key: 'actions', label: this._.actions }
        ],
        }
    },
    instrumentTypeOptions() {
      return {
        895510000: this._.guzheng,
        895510001: this._.erhu,
        895510002: this._.dizi,
        895510003: this._.pipa,
        895510004: this._.yangqin,
        895510005: this._.ruan,
        895510006: this._.liuqin,
        895510007: this._.gaoyinSheng,
        895510008: this._.zhongyinShengGrade,
        895510009: this._.gaoyin,
        895510010: this._.zhongyinSuonaGrade,
        895510011: this._.cello,
        895510012: this._.doubleBass,
        895510013: this._.hulusi,
        895510014: this._.guanzi,
        895510015: this._.percussion,
        895510016: this._.guqin
      }
    },
    instrumentType() {
      return {
        GZ: this._.guzheng,
        EH: this._.erhu,
        DZ: this._.dizi,
        PP: this._.pipa,
        YQ: this._.yangqin,
        ZR: this._.ruan,
        LQ: this._.liuqin,
        SH: this._.gaoyinSheng,
        ZG: this._.zhongyinShengGrade,
        SN: this._.gaoyin,
        ZA: this._.zhongyinSuonaGrade,
        CE: this._.cello,
        DB: this._.doubleBass,
        HU: this._.hulusi,
        GU: this._.guanzi,
        PE: this._.percussion,
        GQ: this._.guqin,
      }
    },
    bookSerialArr() {
      return this.summaryTable.items.filter(s => s.cr098_bookserialnumber).map(s => s.cr098_bookserialnumber) || [];
    },
    typeExam() {
      return [
        {value:false,label:this._.facetoface,text:this._.facetoface},
        {value:true,label:this._.digitalexam,text:this._.digitalexam, disabled:this.formdata.cr098_grade==895510008},
      ]
    },
    grades() {
      return {
        895510000: this._.grade1,
        895510001: this._.grade2,
        895510002: this._.grade3,
        895510003: this._.grade4,
        895510004: this._.grade5,
        895510005: this._.grade6,
        895510006: this._.grade7,
        895510007: this._.grade8,
        895510008: this._.certificateInProfessional
      }
    },
    gradeOptions() { 
      let base = [
        { value: 895510000, label: this._.grade1 },
        { value: 895510001, label: this._.grade2 },
        { value: 895510002, label: this._.grade3 },
        { value: 895510003, label: this._.grade4 },
        { value: 895510004, label: this._.grade5 },
        { value: 895510005, label: this._.grade6 },
        { value: 895510006, label: this._.grade7 },
        { value: 895510007, label: this._.grade8 },
        {
          id: 'conditional',
          value: 895510008,
          label: this._.certificateInProfessional
        }
      ];

      const exceptions = [895510008, 895510010, 895510013];
      
      if (exceptions.includes(+this.formdata.cr098_instrumenttype)) {
        base = base.filter(v => v.id !== 'conditional');
      }

      return base;
    },
    finalData() {
      const formData = JSON.parse(JSON.stringify(this.formdata));

      for (let key in formData) {
        formData[key] = Array.isArray(formData[key])
          ? formData[key].map((a) => String(a)).join(",")
          : String(formData[key]);
      }
      return formData;
    },
    checkPrice(){
      return this.summaryTable.items.map(s=>{
        return {
          grade:s.cr098_grade,
          digital:s.teng_digitalexam
        }
      });
    }
  },
};
</script>

